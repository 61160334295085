import { createSlice } from "@reduxjs/toolkit";
import { selectBook, selectPublicBook } from "./librarySlice";
import { fetchPostsByCategory } from "./wordpressSlice";

const initialState = {
  feedFilter: {
    filter: { search: "", elements: ["Ideas"] },
    sortFilter: "sample",
  },
  libraryFilter: {
    filter: { search: "" },
    sortFilter: "h_progress",
  },
  listViewFilter: {
    showHighlights: true,
  },
  tilesViewFilter: {
    showHighlights: true,
    chapterLevel: {
      label: "1st Level:Chapter",
      value: 1,
    },
  },
  newsLastRead: null,
  lastPostDate: null,
  newsLastChecked: null,
};

export const userConfigSlice = createSlice({
  name: "userConfigSlice",
  initialState,
  reducers: {
    setFeedFilter: (state, action) => {
      state.feedFilter = action.payload;
    },
    setLibraryFilter: (state, action) => {
      state.libraryFilter = action.payload;
    },
    updateListViewFilter: (state, action) => {
      state.listViewFilter = { ...state.listViewFilter, ...action.payload };
    },
    updateTilesViewFilter: (state, action) => {
      state.tilesViewFilter = { ...state.tilesViewFilter, ...action.payload };
    },
    resetListViewFilter: (state) => {
      state.listViewFilter = initialState.listViewFilter;
    },
    resetTilesViewFilter: (state) => {
      state.tilesViewFilter = initialState.tilesViewFilter;
    },
    setNewsLastRead: (state) => {
      state.newsLastRead = new Date();
    },
    setNewsLastChecked: (state) => {
      state.newsLastChecked = new Date();
    },
  },
  extraReducers(builder) {
    builder
      // new book selected, so reset the view filters:
      .addCase(selectBook.fulfilled, (state) => {
        state.listViewFilter = initialState.listViewFilter;
        state.tilesViewFilter = initialState.tilesViewFilter;
      })
      .addCase(selectPublicBook.fulfilled, (state) => {
        state.listViewFilter = initialState.listViewFilter;
        state.tilesViewFilter = initialState.tilesViewFilter;
      })
      .addCase(fetchPostsByCategory.fulfilled, (state, action) => {
        action.payload.map((post) => {
          if (state.lastPostDate === null || state.lastPostDate < post.date) {
            state.lastPostDate = post.date;
          }
        });
      });
  },
});
export const {
  setFeedFilter,
  setLibraryFilter,
  updateListViewFilter,
  updateTilesViewFilter,
  resetListViewFilter,
  resetTilesViewFilter,
  setNewsLastRead,
  setNewsLastChecked,
} = userConfigSlice.actions;
export const userConfigSliceReducer = userConfigSlice.reducer;
export default userConfigSlice;

export const hasNewNews = (state) => {
  const lastPostDate = new Date(
    state.lastPostDate ? state.lastPostDate : state.userConfig?.lastPostDate
  );
  var lastReadDate = new Date(
    state.newsLastRead ? state.newsLastRead : state.userConfig?.newsLastRead
  );
  return lastPostDate === null || lastReadDate === null
    ? true
    : lastPostDate > lastReadDate;
};
